import React from 'react';
import '../pages.scss'
function EditUser(props) {
  return (
    <div>
      
    </div>
  );
}

export default EditUser;