import { Box, TextField, Autocomplete, Button } from "@mui/material";
import { display } from "@mui/system";
import { post } from "../../utils";
import React from "react";
import "../pages.scss";

function AddUser(props) {
  const [userName, setUserName] = React.useState("");
  const [admin, setAdmin] = React.useState(false);
  const [email, setEmail] = React.useState();
  const [password, setPassword] = React.useState();

  return (
    <Box
      sx={{
        flex: 2,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "70%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "flex-start",
        }}
      >
        <TextField
          className="inputField"
          autoComplete="username"
          fullWidth
          required
          label="Benutzername"
          variant="outlined"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <TextField
          className="inputField"
          autoComplete="password"
          fullWidth
          required
          label="Passwort"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          className="inputField"
          autoComplete="email"
          fullWidth
          required
          label="E-Mail"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            console.log("add user");
            post("/admin/addUser", {
              user: userName,
              password: password,
              email: email,
              admin: admin,
            }).then((res) => {
              console.log(res);
            });
          }}
        >
          Benutzer hinzufügen
        </Button>
      </Box>
    </Box>
  );
}

export default AddUser;
