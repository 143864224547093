import './App.css';
import { useState, useEffect} from 'react'
import Welcome from './pages/Welcome'
import Login from './pages/Login'
import ChangePassword from './pages/ChangePassword'
import Logout from './pages/Logout';
import utils from './utils'
import Splash from './pages/Splash';
import Header from './components/Header';
import PageMenu from './components/PageMenu';
import Admin from './pages/Admin';

function App() {
  console.log('APP_ENV', process.env.NODE_ENV)
  console.log('PORT: ', process.env.REACT_APP_API_PORT)

  const [auth, setAuth] = useState()
  const [mustChangePassword, setMustChangePassword] = useState(false)
  const [page, setPage] = useState(0)
  const [pages, setPages] = useState([])
  const [name, setName] = useState('')
  const [admin, setAdmin] = useState(false)
  const [user, setUser] = useState({})

  const pageHome = {
    name: 'Home',
    comp: <Welcome setAuth={setAuth} user={user}/>
  }
  const pageChangePassword = {
    name: 'Passwort ändern',
    comp: <ChangePassword setPage={setPage}/>
  }
  const pageLogout = {
    name: 'Logout',
    comp: <Logout setPage={setPage} setAuth={setAuth}/>
  }
  const pageAdmin = {
    name: 'Admin',
    comp: <Admin />,
  }
  const pagesDefault = [
    pageHome,
    pageChangePassword,
    pageLogout    
  ]
  const pagesAdmin = [
    pageHome,
    pageChangePassword,
    pageLogout,
    pageAdmin
  ]

  const getUserInfo = async () => {
    setUser(await utils.get('/user', setAuth))
  }
    useEffect(() => {
    console.log('cookie: ', utils.readCookie('token'))
      const token = utils.readCookie('token')
      token ? getUserInfo() : setAuth(false)
  }, [])

  useEffect(() => {
    console.log('auth: ', auth)
  }, [auth])

  useEffect(() => {
    if(!user && !mustChangePassword) getUserInfo()
    setPages(user?.admin ? pagesAdmin : pagesDefault)
  }, [user, mustChangePassword])

  return (
    <>
      {auth===undefined && <Splash />}
      {auth===false && <Login setAuth={setAuth} setPage={setPage} setUser={setUser} setMustChangePassword={setMustChangePassword}/>}
      {mustChangePassword === true && <ChangePassword setMustChangePassword={setMustChangePassword} />}
      {auth &&
      <>
        <Header user={user} setPage={setPage}/>
        <PageMenu pages={pages} setPage={setPage} />
        {pages[page]?.comp}
      </>
      }
    </>
  );
}

export default App;
