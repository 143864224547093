import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import './styles.scss'

function Loading(props) {
  return (
    <Box className="loadingContainer">
      <CircularProgress size={20} color="secondary" className="loadingIndicator"/>
    </Box>
  );
}

export default Loading;