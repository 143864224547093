import React from "react";
import "./pages.scss";
import { Box } from "@mui/system";
import { Button, Icon } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import AddUser from "./Admin/AddUser";
import EditUser from "./Admin/EditUser";
import PageMenu from "../components/PageMenu";
function Admin(props) {
  const [page, setPage] = React.useState(0);
  const pages = [
    {
      name: "Benutzer hinzufügen",
      icon: <PersonAddIcon />,
      comp: <AddUser />,
    },
    {
      name: "Benutzer bearbeiten",
      icon: <PersonSearchIcon />,
      comp: <EditUser />,
    },
  ];
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <PageMenu pages={pages} setPage={setPage} />
      {pages[page].comp}
    </Box>
  );
}

export default Admin;
