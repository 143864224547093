import { Box } from '@mui/system';
import React from 'react';
import './styles.scss'
import { Typography } from '@mui/material';

function Header(props) {
  return (
   <Box className="pageHeader" >
      <Typography className="pageHeaderItem" variant="h4" component="div" gutterBottom>
        Hallo, <span className="capitalize">{props.user?.name}</span>!
        </Typography>

   </Box>
  );
}

export default Header;