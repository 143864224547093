const options = {
  redirect: 'follow',
  credentials: 'include',
  mode: 'cors',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
}
console.log('process.env.REACT_APP_API_PATH: ', process.env.REACT_APP_API_PATH)
const url = process.env.REACT_APP_API_PATH
console.log('url: ', url)

const handleErrors = async (data, status) => {
  eraseCookie('token')
  switch(status) {
    case 401:
      return {...data, auth: false, message: 'Du bist nicht eingeloggt'}
    case 403:
      return {...data, auth: false, message: 'Du hast keine Berechtigung'}
    default:
      return {...data, auth: false, message: 'Es ist irgendetwas schief gelaufen. Nicht Deine Schuld'}
  }
}
      


const handleResponse = async (res, setAuth) => {
  const data = await res.json()
  setAuth?.(res.ok)
  if(!res.ok) return handleErrors(data, res.status)
  else return {...data, auth: true}
}
// async wrapper for fetch get with error handling
export async function get(path, setAuth) {
  const res = await fetch(`${url}${path}`, {
    method: 'GET',
    ...options
  })
  return await handleResponse(res, setAuth)
}

// async wrapper for fetch post with error handling
export async function post(path, data, setAuth) {
  const res = await fetch(`${url}${path}`, {
    method: 'POST',
    ...options,
    body: JSON.stringify(data)
  })
  return await handleResponse(res, setAuth)    
}


export const setCookie = function (name, value, days) {
  var expires
  
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    expires = '; expires=' + date.toGMTString()
  } else {
    expires = ''
  }
  document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value) + expires + '; path=/'
}

export const readCookie = function (name) {
  var nameEQ = encodeURIComponent(name) + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') { c = c.substring(1, c.length) }
    if (c.indexOf(nameEQ) === 0) { return decodeURIComponent(c.substring(nameEQ.length, c.length)) }
  }
  return null
}
// function to create a cookie
export const eraseCookie = function (name, value, days) {
  console.log('eraseCookie')
  document.cookie = "token=;expires=" + new Date(0).toUTCString()
  //console.log(readCookie('token'))
}


const newLocal = { get, post, readCookie, setCookie, eraseCookie }
export default newLocal



