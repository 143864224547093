import React from 'react';

function Welcome(props) {
  return (
    <div>
      Welcome {props.name}
    </div>
  );
}

export default Welcome;