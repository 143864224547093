import { AppBar, Tabs, Tab } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import "./styles.scss";
function PageMenu(props) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    console.log("new value: ", newValue);
    setValue(newValue);
    props.setPage(newValue);
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
        >
          {props.pages.map((page, index) => {
            return (
              <Tab label={page.name} icon={page.icon} id={`tab${index}`} />
            );
          })}
        </Tabs>
      </AppBar>
    </Box>
  );
}

export default PageMenu;
