import React from 'react';
import { TextField, Button } from '@mui/material';
import  Box  from '@mui/material/Box';
import utils from '../utils'
import Loading from '../components/Loading';
function ChangePassword(props) {
  const [password1, setPassword1] = React.useState('')
  const [password2, setPassword2] = React.useState('')
  const [error, setError] = React.useState()
  const [isLoading, setIsLoading] = React.useState(false)

  const changePassword = async () => {
    setIsLoading(true)
    const result = await utils.post('/changepassword', {password1, password2})
    console.log('result: ', result)
    props.setMustChangePassword(result.mustChangePassword===true)
    props.setUser(result.user)
  }

  return (
    <Box className="pageContainer">
      <Box className="loginContainer"
        component="form"      
        noValidate
        autoComplete="off"
    >
      <div className="description">
        {props.description || "Du musst ein neues Passwort vergeben um fortzufahren."}
      </div>
      <TextField className="inputField"
        autoComplete='current-password'
        fullWidth
        error={password1.length < 6 }
        helperText={password1.length < 6 ? 'Passwort muss mindestens 6 Zeichen lang sein' : ''}
        required
        type='password'
        label="neues Passwort"
        value={password1}
        onChange={e => setPassword1(e.target.value)}
      ></TextField>
      <TextField className="inputField"
        autoComplete='current-password'
        fullWidth
        error={!!error || (password1 !== password2 && password2.length > 0)}
        helperText={password1 !== password2 && password2.length > 0 ? 'Passwörter müssen übereinstimmen' : error ? error : ''}
        required
        type='password'
        label="Passwort wiederholen"
        value={password2}
        onChange={e => setPassword2(e.target.value)}
        onKeyPress={e => {if(e.key === 'Enter') {changePassword()}}}
      >
      </TextField>
      <Button className='btnLogin'
        disabled={password1.length < 6 || password1 !== password2}
        onClick={changePassword}
        variant="contained">Login</Button>

    </Box>
    {isLoading && <Loading />}
  </Box>
  );
}

export default ChangePassword;