import React from 'react';
import Loading from '../components/Loading'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import utils from '../utils'
import './pages.scss'
function Login({setPage, setUser, setMustChangePassword, setAuth}) {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState()
  const [isLoading, setIsLoading] = React.useState(false)
  const returnTo = new URLSearchParams(window.location.search).get('rt')
  console.log('returnTo: ', returnTo)
  const authenticate = async () => {
    setIsLoading(true)
    const result = await utils.post('/login', {username, password}, setAuth)
    console.log('result: ', result)
    setError(result?.message)
    setMustChangePassword(result?.mustChangePassword)
    setAuth(result?.auth || result.mustChangePassword)
    setIsLoading(false)
    if(result?.auth){
      setUser(result.user)
      returnTo!==null ? window.location.href = returnTo : setPage(0)    
    }
  }
  React.useEffect(() => {
    setError(password.length < 6 ? 'Passwort muss mindestens 6 Zeichen lang sein' : '')
  }, [password])
  return (
    <Box className="pageContainer">
      <Box className="loginContainer"
        component="form"      
        noValidate
        autoComplete="off"
    >
      <TextField className="inputField"
        autoComplete='username'
        fullWidth
        error={username.length < 3 }
        helperText={username.length < 3 ? 'Benutzername muss mindestens 3 Zeichen lang sein' : ''}
        required
        label="Benutzername"
        value={username}
        onChange={e => setUsername(e.target.value)}
      ></TextField>
      <TextField className="inputField"
        autoComplete='current-password'
        fullWidth
        error={!!error || password.length < 6}
        helperText={error}
        required
        type='password'
        label="Passwort"
        value={password}
        onChange={e => setPassword(e.target.value)}
        onKeyPress={e => {if(e.key === 'Enter') {authenticate()}}}
      >
      </TextField>
      <Button className='btnLogin'
        disabled={username.length < 3 || password.length < 6}
        onClick={authenticate}
        variant="contained">Login</Button>

    </Box>
    {isLoading && <Loading />}
  </Box>
  );
}

export default Login;