import React from 'react';
import './pages.scss'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import utils from '../utils';
import Loading from '../components/Loading';

function Logout(props) {
  const [loading, setLoading] = React.useState(false);
  const handleLogout = async () => {
    setLoading(true);
    
    setLoading(false);
    await utils.post('/logout')
    props.setAuth(false)
  }
 
  return (
    <Box className="pageContainer">
    {loading ? <Loading /> : <Button variant="contained" className="btnLogout" onClick={handleLogout}>Logout</Button>}   
    </Box>
  );
}

export default Logout;